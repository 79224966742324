import React, { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RecordForm from "./RecordForm";
import { Form } from "../../../components/forms";
import {
    useGetOneAssetsTypeQuery,
    useConfirmAssetMutation,
    useCreateAssetMutation,
    useGetAssetsTypeQuery,
    useGetNodesListQuery,
    useGetUnitsQuery,
} from "../../../services/supplyChainService";
import { useSDK } from "@thirdweb-dev/react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useGetProjectDetailQuery } from "../../../services/projectService";
import { logEvent } from "../../../utils/amplitudeUtlis";
import Loading from "../../../components/LoadingSkeleton/Loading";
import { useUploadFileMutation } from "../../../services/fileManagementService";
import DependencyContainer from "./DependencyContainer";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
    asset_id: Yup.string().required(
        "The Product ID must not be empty or just spaces",
    ),
});

export default function AddRecordPage() {
    const sdk = useSDK();
    const navigate = useNavigate();
    const { id: projectId } = useParams();
    const { batchId } = useParams();

    const {
        data: project,
        error,
        isProjectLoading,
    } = useGetProjectDetailQuery(projectId);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingStep, setLoadingStep] = useState("");
    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [createAsset] = useCreateAssetMutation();
    const [uploadFile] = useUploadFileMutation();
    const [dependencies, setDependencies] = useState([]);
    const [hasMissingDependencies, setHasMissingDependencies] = useState(false);
    const [missingDependenciesMap, setMissingDependenciesMap] = useState({});

    const { data: nodesResponse, isLoading: isLoadingNodes } =
        useGetNodesListQuery(projectId);

    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsTypeQuery(projectId);

    const [groupRepeatCount, setGroupRepeatCount] = useState({});
    const { data: unitsData, isLoading: isUnitsLoading } = useGetUnitsQuery();
    useEffect(() => {
        if (selectedAssetType?.dependencyConfig?.dependencies) {
            setDependencies(
                selectedAssetType.dependencyConfig.dependencies.map((dep) => ({
                    ...dep,
                    selectedAssets: [],
                })),
            );
        } else {
            setDependencies([]);
        }
    }, [selectedAssetType]);

    useEffect(() => {
        // Effect to keep track of the grouprepat counts
        // When select asset changes we set counts to 1 each
        if (selectedAssetType && selectedAssetType.formConfig?.fields) {
            setGroupRepeatCount(
                selectedAssetType.formConfig.fields
                    .filter((field) => field.type === "group")
                    .reduce(
                        (acc, field) => ({ ...acc, [field.groupId]: 1 }),
                        {},
                    ),
            );
        }
    }, [selectedAssetType]);

    const handleCloseClick = () => {
        try {
            navigate(-1);

            logEvent({
                eventName: "cancel_button_clicked",
                eventProperties: {
                    page_name: "Add Record Page",
                },
            });
        } catch (error) {
            navigate(`/projects/${projectId}/batches/${batchId}`);
        }
    };

    const nodeList = useMemo(() => {
        return nodesResponse?.data.map((node) => ({
            label: node.name,
            value: node.id,
        }));
    }, [nodesResponse]);

    const assetTypeList = useMemo(() => {
        return assetTypesResponse?.data?.map((assetType) => ({
            id: assetType.id,
            label: assetType.type_name,
            value: assetType.id,
            dependencyConfig: assetType.dependency_config,
            formConfig: assetType.form_config,
            unit_category: assetType.unit_category.id,
        }));
    }, [assetTypesResponse]);

    const handleDependencyChange = (updatedDependency) => {
        setDependencies((prevDeps) =>
            prevDeps.map((dep) =>
                dep.asset_type_id === updatedDependency.asset_type_id
                    ? updatedDependency
                    : dep,
            ),
        );
    };

    const handleRemoveDependency = (assetTypeId, index) => {
        setDependencies((prevDeps) =>
            prevDeps.map((dep) =>
                dep.asset_type_id === assetTypeId
                    ? {
                          ...dep,
                          selectedAssets: dep.selectedAssets.filter(
                              (_, i) => i !== index,
                          ),
                      }
                    : dep,
            ),
        );
    };

    const handleMissingDependencies = useCallback((assetTypeId, hasMissing) => {
        setMissingDependenciesMap((prev) => {
            const newMap = { ...prev, [assetTypeId]: hasMissing };
            const hasAnyMissing = Object.values(newMap).some(Boolean);

            // Batch updates
            setHasMissingDependencies(hasAnyMissing);
            return newMap;
        });
    }, []);

    const unitOptions = useMemo(() => {
        if (!unitsData?.data || !selectedAssetType?.unit_category) return [];

        // Find the category that matches selected asset type's unit_category
        const selectedCategory = unitsData.data.find(
            (category) => category.id === selectedAssetType.unit_category,
        );

        if (!selectedCategory) return [];

        // Map units from the matching category
        return selectedCategory.units.map((unit) => ({
            label: `${unit.name} (${unit.symbol})`,
            value: unit.id,
        }));
    }, [unitsData, selectedAssetType]);

    const handleAssetCreate = async (values) => {
        setIsLoading(true);
        setLoadingStep("Validating data...");

        logEvent({
            eventName: "record_creation_initiated",
            eventProperties: {
                page_name: "Add Record Page",
            },
        });
        // Format dependencies to match backend expectations
        const formattedDependencies = dependencies.reduce((acc, dep) => {
            if (dep.selectedAssets && dep.selectedAssets.length > 0) {
                dep.selectedAssets.forEach((asset) => {
                    acc.push({
                        asset_uuid: asset.assetUuid,
                        qty: asset.quantity,
                    });
                });
            }
            return acc;
        }, []);

        let formConfig = [];
        for (let field in values.field) {
            // Check if the field is an image
            if (field.includes("group")) {
                const groupId = field.replace("group", "");

                let valueSets = [];

                for (let groupField of values.field[field]) {
                    let groupFields = [];

                    for (let key in groupField) {
                        const assetTypeConfig =
                            selectedAssetType.formConfig.fields.find(
                                (config) =>
                                    config.name === key &&
                                    config.groupId === groupId,
                            );

                        if (!assetTypeConfig) {
                            continue;
                        }

                        if (
                            assetTypeConfig.type === "file" ||
                            assetTypeConfig.type === "image"
                        ) {
                            try {
                                const file = groupField[key];
                                if (!file) continue;

                                const formData = new FormData();
                                formData.append("file", file);

                                const uploadResponse = await uploadFile({
                                    file: formData,
                                });
                                if (uploadResponse.error) {
                                    throw new Error("File upload failed");
                                }

                                groupFields.push({
                                    ...assetTypeConfig,
                                    value: uploadResponse.data?.data?.id,
                                    originalFileName: file.name,
                                });
                            } catch (error) {
                                toast.error(
                                    "File upload failed: " + error.message,
                                );
                                setIsLoading(false);
                                return;
                            }
                        } else {
                            groupFields.push({
                                ...assetTypeConfig,
                                value: groupField[key],
                            });
                        }
                    }

                    valueSets.push(groupFields);
                }

                formConfig.push({
                    groupId: groupId,
                    label: "Group",
                    type: "group",
                    value: valueSets,
                });
            } else {
                const assetTypeConfig =
                    selectedAssetType.formConfig.fields.find(
                        (config) => config.name === field,
                    );

                if (!assetTypeConfig) {
                    continue;
                }

                if (
                    assetTypeConfig.type === "file" ||
                    assetTypeConfig.type === "image"
                ) {
                    try {
                        const file = values.field[field];
                        if (!file) continue;

                        const formData = new FormData();
                        formData.append("file", file);

                        const uploadResponse = await uploadFile({
                            file: formData,
                        });
                        if (uploadResponse.error) {
                            throw new Error("File upload failed");
                        }
                        formConfig.push({
                            ...assetTypeConfig,
                            value: uploadResponse.data?.data?.id,
                            originalFileName: file.name,
                        });
                    } catch (error) {
                        toast.error("File upload failed: " + error.message);
                        setIsLoading(false);
                        return;
                    }
                } else {
                    formConfig.push({
                        ...assetTypeConfig,
                        value: values.field[field],
                    });
                }
            }
        }

        try {
            console.log("Debug: Creating the asset in backend");
            setLoadingStep("Creating record...");
            console.log({
                id: projectId,
                asset_id: values.asset_id,
                asset_type: selectedAssetType.id,
                node: values.node,
                dependencies: formattedDependencies,
                form_data: formConfig,
            });
            const response = await createAsset({
                id: projectId,
                asset_id: values.asset_id,
                asset_type: selectedAssetType.id,
                unit: values.unit,
                qty: values.qty,
                node: values.node,
                dependencies: formattedDependencies,
                form_data: formConfig,
                batch_id: batchId,
            });
            if (response.error) {
                if (response.error.data?.errors) {
                    const errorMessages = response.error.data.errors.map(
                        (error) => {
                            const [field, messages] = Object.entries(error)[0];
                            return `${field}: ${messages.join(", ")}`;
                        },
                    );
                    throw new Error(errorMessages.join("\n"));
                }
                throw new Error("Could not create the asset record");
            }

            logEvent({
                eventName: "record_creation_completed",
                eventProperties: {
                    record_id: values.asset_id,
                    project_id: projectId,
                },
            });

            const asset = response.data.data.asset;
            const instructions = response.data.data.instructions;

            console.log(asset);
            console.log(instructions);
            console.log("Debug: Asset created, now trying to execute txn");

            if (instructions && instructions.length > 0) {
                setLoadingStep("Doing blockchain magic...");

                for (var instruction of instructions) {
                    console.log("Debug: Trying txn -", instruction);
                    const contract = await sdk.getContractFromAbi(
                        instruction.contract,
                        instruction.abi,
                    );
                    const txn = await contract.call(
                        instruction.command,
                        instruction.args,
                        {
                            gasLimit: 100000000,
                        },
                    );
                    console.log("Debug:", instruction.command, txn);
                }
            } else {
                console.log("Debug: No blockchain instructions to execute");
            }

            toast("Asset record added!");
            handleCloseClick();
        } catch (error) {
            const errorMessage =
                error.message || "An unexpected error occurred";
            if (errorMessage.includes("Asset with id")) {
                toast.error(errorMessage);
            } else if (errorMessage.includes("timeout")) {
                toast(
                    "Transaction to the blockchain is taking longer than usual. If it fails this record might be archived.",
                );
            } else {
                toast.error(errorMessage);
            }

            logEvent({
                eventName: "record_creation_failed",
                eventProperties: {
                    errorMessage: error,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnAssetTypeSelectionChange = (event) => {
        const selection = event.target.value;
        const assetType = assetTypeList.find((at) => at.value === selection);

        setSelectedAssetType(assetType || null);
        setMissingDependenciesMap({});
        setHasMissingDependencies(false);
    };

    const handleAddRepeatingGroup = (groupId) => {
        setGroupRepeatCount({
            ...groupRepeatCount,
            [groupId]: 1 + (groupRepeatCount[groupId] || 0),
        });
    };

    const handleRemoveRepeatingGroup = (groupId) => {
        setGroupRepeatCount({
            ...groupRepeatCount,
            [groupId]:
                groupRepeatCount[groupId] > 1
                    ? groupRepeatCount[groupId] - 1
                    : 1,
        });
    };

    /**
     * FIXME: Need to add proper loading indicators while these load
     */
    if (isLoadingAssetTypes || isLoadingNodes) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="h-100 min-vh-100 w-100 m-0 px-4">
            <div className="mt-3">
                <Typography variant="body1">
                    Overview / {project?.data?.name} / Add record
                </Typography>
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography variant="h1">Add new record</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-1 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="divider-line mt-4"></div>
            </div>
            <div className="container col-xxl-8 px-4 mt-5">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 mx-auto justify-content-center">
                        <Typography variant="h2">Record Details</Typography>
                        <Form
                            initialValues={{
                                asset_id: "",
                            }}
                            onSubmit={handleAssetCreate}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                        >
                            <RecordForm
                                fieldType="text"
                                name="asset_id"
                                label="Product ID"
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="node"
                                label="Node"
                                options={nodeList}
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="asset_type"
                                label="Asset Type"
                                options={assetTypeList}
                                onChange={handleOnAssetTypeSelectionChange}
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="unit"
                                label="Unit"
                                options={unitOptions}
                                disabled={!selectedAssetType}
                            />
                            <RecordForm
                                fieldType="number"
                                name="qty"
                                label="Quantity"
                            />
                            {selectedAssetType && (
                                <>
                                    {dependencies.length > 0 && (
                                        <div className="dependencies-container mt-5">
                                            <Typography variant="h2">
                                                Asset Dependencies
                                            </Typography>

                                            {hasMissingDependencies && (
                                                <div className="alert alert-warning mt-2 d-flex align-items-center">
                                                    <i className="bi bi-exclamation-triangle-fill me-2"></i>
                                                    <span>
                                                        Asset creation will fail
                                                        because required
                                                        dependencies are not
                                                        available
                                                    </span>
                                                </div>
                                            )}
                                            <div className="mt-4">
                                                {dependencies.map(
                                                    (dep, index) => (
                                                        <DependencyContainer
                                                            key={
                                                                dep.asset_type_id
                                                            }
                                                            index={index}
                                                            dependency={dep}
                                                            unitOptions={
                                                                unitOptions
                                                            }
                                                            onDependencyChange={
                                                                handleDependencyChange
                                                            }
                                                            onRemoveDependency={
                                                                handleRemoveDependency
                                                            }
                                                            projectId={
                                                                projectId
                                                            }
                                                            onMissingDependencies={(
                                                                hasMissing,
                                                            ) =>
                                                                handleMissingDependencies(
                                                                    dep.asset_type_id,
                                                                    hasMissing,
                                                                )
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {selectedAssetType.formConfig?.fields?.map?.(
                                        (field) => {
                                            if (field.type === "group") {
                                                let groupFieldRender = [];

                                                for (
                                                    let repeat = 0;
                                                    repeat <
                                                    groupRepeatCount[
                                                        field.groupId
                                                    ];
                                                    repeat++
                                                ) {
                                                    groupFieldRender.push(
                                                        <div
                                                            className="field-group-container"
                                                            key={`group_${field.groupId}_repeat_${repeat}`}
                                                        >
                                                            <div className="field-group-header d-flex p-3 pb-0">
                                                                <div className="text-3">
                                                                    Group{" "}
                                                                    {
                                                                        field.groupId
                                                                    }
                                                                </div>
                                                                {repeat > 0 &&
                                                                    repeat ===
                                                                        groupRepeatCount[
                                                                            field
                                                                                .groupId
                                                                        ] -
                                                                            1 && (
                                                                        <div
                                                                            className="text-3 ms-auto field-group-action-remove"
                                                                            onClick={() =>
                                                                                handleRemoveRepeatingGroup(
                                                                                    field.groupId,
                                                                                )
                                                                            }
                                                                        >
                                                                            X
                                                                            Remove
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className="mx-3 field-group-fields">
                                                                {selectedAssetType.formConfig.fields
                                                                    .filter(
                                                                        (
                                                                            groupField,
                                                                        ) =>
                                                                            groupField.type !==
                                                                                "group" &&
                                                                            groupField.groupId ===
                                                                                field.groupId,
                                                                    )
                                                                    .map(
                                                                        (
                                                                            groupField,
                                                                        ) => {
                                                                            return (
                                                                                <RecordForm
                                                                                    fieldType={
                                                                                        groupField.type
                                                                                    }
                                                                                    name={`field.group${groupField.groupId}.${repeat}.${groupField.name}`}
                                                                                    label={
                                                                                        groupField.label
                                                                                    }
                                                                                />
                                                                            );
                                                                        },
                                                                    )}
                                                            </div>
                                                            <div className="field-group-actions">
                                                                {repeat ===
                                                                    groupRepeatCount[
                                                                        field
                                                                            .groupId
                                                                    ] -
                                                                        1 && (
                                                                    <div
                                                                        className="m-3 add-another-node"
                                                                        onClick={() =>
                                                                            handleAddRepeatingGroup(
                                                                                field.groupId,
                                                                            )
                                                                        }
                                                                    >
                                                                        + Add
                                                                        another
                                                                        value
                                                                        set
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>,
                                                    );
                                                }

                                                return groupFieldRender;
                                            }

                                            if (
                                                field.groupId &&
                                                field.groupId !== "0"
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <RecordForm
                                                    fieldType={field.type}
                                                    name={`field.${field.name}`}
                                                    label={field.label}
                                                />
                                            );
                                        },
                                    )}
                                </>
                            )}

                            <div className="my-4">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    fullWidth={false}
                                    isLoading={isLoading}
                                    loadingText={loadingStep}
                                    disabled={hasMissingDependencies}
                                >
                                    Add records
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
