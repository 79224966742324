import React, { useState } from "react";
import {
    Checkbox,
    FileField,
    Select,
    TextField,
} from "../../../components/forms";
import TextArea from "../../../components/forms/TextArea";
import { useGetAssetsListQuery } from "../../../services/supplyChainService";
import UnifiedFileUpload from "../../../components/forms/UnifiedFileUpload";
import { useParams } from "react-router-dom";

const RecordForm = ({
    fieldType,
    name,
    label,
    options = [],
    typeName = null,
    ...props
}) => {
    const { id } = useParams();

    const { data: assetResponse, isLoading: isLoadingAssets } =
        useGetAssetsListQuery({ projectId: id });

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const onFileSelected = (file) => {
        setFiles([file]);
        setSelectedFile(file);
    };

    const renderField = () => {
        switch (fieldType) {
            case "text":
                return <TextField name={name} label={label} />;
            case "number":
                return (
                    <TextField
                        name={name}
                        label={label}
                        type="number"
                        min="0"
                        onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                                e.preventDefault();
                            }
                        }}
                        {...props}
                    />
                );
            case "date":
                return <TextField name={name} label={label} type="date" />;
            case "dropdown":
                return (
                    <Select
                        name={name}
                        label={label}
                        options={options}
                        {...props}
                    />
                );
            case "file":
                return (
                    <UnifiedFileUpload
                        name={name}
                        fileType="document"
                        label={label}
                        onFileSelected={onFileSelected}
                        selectedFile={selectedFile}
                        width="100%"
                    />
                );
            case "image":
                return (
                    <UnifiedFileUpload
                        name={name}
                        fileType="image"
                        label={label}
                        onFileSelected={onFileSelected}
                        selectedFile={selectedFile}
                        width="100%"
                    />
                );
            case "checkbox":
                return <Checkbox name={name} label={label} />;
            case "description":
                return <TextArea name={name} label={label} />;
            default:
                return <TextField name={name} label={label} />;
        }
    };

    return <div className="my-4">{renderField()}</div>;
};

export default RecordForm;
