import React, { useMemo, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import CarousalImageCard from "../../../components/Card/CarousalImageCard";
import "../Trace/trace.scss";
import { useDownloadFileQuery } from "../../../services/fileManagementService";
import { getExplorerLink } from "../../../web3/helpers/utils";

function Section({
    title,
    description,
    location,
    location_meta_data,
    mapImage,
    details,
    images,
    id,
    tokenId,
    txnHash,
    contractAddress,
    nodeFormData,
    chain,
}) {
    const explorer_url = useMemo(() => getExplorerLink(chain), [chain]);

    const node_location_url = useMemo(() => {
        const { lat, lon } = location_meta_data || {};
        const query = lat && lon ? `${lat},${lon}` : location;
        return `https://www.google.com/maps?q=${query}`;
    }, [location, location_meta_data]);

    return (
        <div>
            <div className="row m-0">
                <div className="col-12 col-md-8 offset-md-2 pt-2">
                    <div className="pt-2 ps-1 ps-md-0">
                        <Typography
                            variant="display3"
                            className="m-0  d-flex justify-content-md-center"
                        >
                            {title}
                        </Typography>
                    </div>
                    <div className="pt-3">
                        <CarousalImageCard images={images} />
                        <div className="d-flex justify-content-center mt-3 text-start mx-2">
                            <Typography variant="body1" className="m-0">
                                {description}
                            </Typography>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="card mx-2 mb-3 border-0">
                            <div className="row g-0">
                                <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                    <div
                                        style={{
                                            backgroundColor: "#E2F1FF",
                                            width: "50px",
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src="/icons/vuesax-bold-location.svg"
                                            className="img-fluid rounded-start"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="col-9 col-md-10">
                                    <div className="card-body">
                                        <Typography
                                            variant="body1"
                                            className="m-0"
                                        >
                                            {location}
                                        </Typography>
                                        <a
                                            className="card-text open-map"
                                            href={node_location_url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Open the map
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {details.map((item, index) => (
                        <div className="col-12 mt-2" key={index}>
                            <div className="card mx-2 mb-3 border-0">
                                {item.type === "valueset" && (
                                    <>
                                        {item.value.map((groupItem) => (
                                            <FormDetail item={groupItem} />
                                        ))}
                                    </>
                                )}
                                {item.type !== "valueset" && (
                                    <FormDetail item={item} />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-100 d-flex justify-content-center mb-4">
                    {txnHash && (
                        <a
                            href={`${explorer_url}/tx/${txnHash}`}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Verify
                        </a>
                    )}
                    {tokenId && (
                        <a
                            href={`${explorer_url}/token/${contractAddress}?a=${tokenId}`}
                            className="btn btn-primary ms-2"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View Token
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}

function FormDetail({ item, index }) {
    return (
        <div className="row g-0">
            <div className="col-12">
                <div className="card-body">
                    <Typography variant="body1" className="m-0" color="#70B8FF">
                        {item.key}
                    </Typography>
                    {item.type === "image" || item.type === "file" ? (
                        <FileDetail fileId={item.value} />
                    ) : (
                        <Typography
                            variant="body1"
                            className="m-0"
                            color="#6C6C6F"
                        >
                            {item.value}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
}

function FileDetail({ fileId }) {
    const { data, error, isLoading } = useDownloadFileQuery({ fileId });

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error loading file</p>;

    const fileUrl = data?.data?.file;
    const fileName = data?.data?.file_name || "File";
    const fileExtension = fileName.split(".").pop()?.toLowerCase();

    const renderFilePreview = () => {
        switch (fileExtension) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return <img src={fileUrl} alt="Detail" className="img-fluid" />;

            case "pdf":
                return (
                    <div className="file-preview pdf">
                        <i className="bi bi-file-earmark-pdf fs-1" />
                        <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ms-2"
                        >
                            View PDF
                        </a>
                    </div>
                );

            case "doc":
            case "docx":
                return (
                    <div className="file-preview doc">
                        <i className="bi bi-file-earmark-word fs-1" />
                        <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ms-2"
                        >
                            View Document
                        </a>
                    </div>
                );

            case "xls":
            case "xlsx":
                return (
                    <div className="file-preview excel">
                        <i className="bi bi-file-earmark-excel fs-1" />
                        <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ms-2"
                        >
                            View Spreadsheet
                        </a>
                    </div>
                );

            default:
                return (
                    <div className="file-preview">
                        <i className="bi bi-file-earmark fs-1" />
                        <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ms-2"
                        >
                            Download File
                        </a>
                    </div>
                );
        }
    };

    return <div className="file-detail">{renderFilePreview()}</div>;
}
export default Section;
